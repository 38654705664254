<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div style="margin-bottom:10px">
        <v-btn color="primary" >
          Ga terug
        </v-btn>
      </div>
      <v-row style="margin-top: 15px">
        <v-col cols="12">
          <v-text-field
            v-model="filter"
            append-icon="mdi-magnify"
            label="Werkplek opzoeken"
            single-line
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="searchFilteredAll"
            disable-pagination
            :hide-default-footer="true"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Alle Werkplekken</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-icon small>
                mdi-eye
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="resetButton()">
                Reset
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from "../api/apiOffice";

export default {

  data: () => ({
    filter: "",
    items: [],
    headers: [
      {
        text: "Naam",
        value: "officeName",
        ble: false,
      },

      {
        text: "Adres",
        value: "adres",
        sortable: true,
      },
      {
        text: "Type",
        value: "type",
        sortable: true,
      },

      {
        text: "Eigenaar",
        value: "owner_Id",
        sortable: true,
      },
      {
        text: "Actions",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    searchFilteredAll() {
      if (!this.filter) return this.items;
      let searchTerm = this.filter.toLowerCase();
      return this.items.filter((item) =>
        item.officeName.toLowerCase().includes(searchTerm)
      );
    },
  },
  async mounted() {
    api.getAllPlaces().then((data) => {
      this.items = data;
    });
  },


};
</script>
