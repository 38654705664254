<template>
  <div>
    <AllWorkSpaces />
  </div>
</template>

<script>
import AllWorkSpaces from "@/components/admin/AllWorkSpaces";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    AllWorkSpaces,
  },
  metaInfo: {
    title: "Alle werkplekken | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
